import { useMediaQuery, useTheme } from '@mui/material';

export const useScreenSize = () => {
  const th = useTheme();
  const isMobile = useMediaQuery(th.breakpoints.down('sm'));

  return {
    isMobile,
  };
};
