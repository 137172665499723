import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { MarbleButton } from 'src/base/inputs/Button';
import { MarbleText } from 'src/base/texts/Text';
import { useScreenSize } from 'src/hooks/useScreenSize';

interface FeatureBoxProps {
  header: string;
  descr: string;
  children: JSX.Element;
  moreInfo?: {
    text: string;
    link: string;
  };
  reverseImgOrder?: boolean;
}

export function FeatureBox(props: FeatureBoxProps): JSX.Element {
  const reverse = props.reverseImgOrder ?? false;
  const { isMobile } = useScreenSize();
  return (
    <Grid
      container
      direction={reverse ? 'row-reverse' : 'row'}
      height="100%"
      sx={{
        p: 0,
        m: 0,
        mb: 15,
      }}
    >
      <Grid item xs={12} sm={6} p={0} m={0}>
        {props.children}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        p={0}
        m={0}
        sx={(t) => ({
          [t.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pr: reverse ? 5 : 0,
            pl: reverse ? 0 : 5,
          },
          [t.breakpoints.down('sm')]: {
            pt: 5,
          },
        })}
      >
        {isMobile ? (
          <MarbleText size="xl" weight="med">
            {props.header}
          </MarbleText>
        ) : (
          <MarbleText size="h2" weight="med">
            {props.header}
          </MarbleText>
        )}
        <MarbleText weight="med">{props.descr}</MarbleText>
        {props.moreInfo !== undefined ? (
          <Box
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              pt: 5,
            }}
          >
            <MarbleButton
              title={props.moreInfo.text}
              href={props.moreInfo.link}
              color="gray"
              variant="outlined"
            />
          </Box>
        ) : (
          <> </>
        )}
      </Grid>
    </Grid>
  );
}
