import { Box, Container, Grid } from '@mui/material';
import Image from 'next/image';
import React from 'react';
import { MarbleText } from 'src/base/texts/Text';

interface BannerProps {
  svg: string;
  title: string;
  caption: string;
  imgAlt?: string;
  actionComponent?: any;
  titleStyle?: React.CSSProperties;
}

export default function Banner(props: BannerProps): JSX.Element {
  return (
    <Container>
      <Grid
        container
        style={{
          paddingTop: 40,
          marginTop: 20,
        }}
        sx={(theme) => ({
          flexDirection: theme.breakpoints.up('xs') ? 'row-reverse' : undefined,
        })}
      >
        <Grid item xs={12} sm={6}>
          <Image
            width={540}
            height={360}
            alt={props.imgAlt}
            src={props.svg}
            style={{
              borderRadius: 5,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={(t) => ({
            [t.breakpoints.up('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              pr: 5,
            },
            [t.breakpoints.down('sm')]: {
              pt: 5,
            },
          })}
        >
          <MarbleText size="h2" weight="bold">
            {props.title}
          </MarbleText>
          <Box
            sx={{
              mt: 1,
              mb: 9,
              maxWidth: 500,
            }}
          >
            <MarbleText weight="med">{props.caption}</MarbleText>
          </Box>
          {props.actionComponent ?? <div />}
        </Grid>
      </Grid>
    </Container>
  );
}
