import { Box, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Image from 'next/image';
import React from 'react';
import { FeatureBox } from 'src/components/features/FeatureBox';
import withMarketingLayout from 'src/components/layout/MarketingLayout';
import { NextPageWithLayout } from 'src/utils/types';
import Carousel from 'react-material-ui-carousel';
import PricingDisplay from 'src/components/PricingDisplay';
import { MarbleButton } from 'src/base/inputs/Button';
import Banner from 'src/components/Banner';
import { MarbleText } from 'src/base/texts/Text';
import { CONTROL_LEASING_FEE } from 'server-sdk/src/constants';
import { useScreenSize } from 'src/hooks/useScreenSize';
import { theme } from 'ui-sdk/src/theme';

const Home: NextPageWithLayout = () => {
  const { isMobile } = useScreenSize();

  return (
    <div>
      <Banner
        svg="/img/hero_desktop.webp"
        imgAlt="Beautiful rental home in the valley"
        title="Let Marble manage your rental."
        titleStyle={{
          paddingTop: 10,
          fontWeight: 600,
        }}
        caption="Our virtual management team will take care of everything for just $65 flat fee per month per unit, period."
        actionComponent={
          <MarbleButton
            href="/start"
            title="Get Started"
            color="black"
            fullWidth
          />
        }
      />

      <Box
        sx={(t) => ({
          [t.breakpoints.up('sm')]: {
            mt: 20,
          },
          [t.breakpoints.down('sm')]: {
            mt: 15,
          },
          pl: 0,
          pr: 0,
        })}
      >
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {isMobile ? (
            <MarbleText
              weight="bold"
              size="xl"
              align="left"
              sx={{
                pb: 5,
                pl: 3,
              }}
            >
              Simple pricing for everyone
            </MarbleText>
          ) : (
            <MarbleText
              weight="bold"
              size="h2"
              align="center"
              sx={{
                pb: 5,
              }}
            >
              Simple pricing for everyone
            </MarbleText>
          )}
          <div style={{ padding: 20 }}>
            <PricingDisplay setup={CONTROL_LEASING_FEE} />
          </div>
        </Container>
      </Box>

      <Container
        sx={(t) => ({
          [t.breakpoints.up('sm')]: {
            mt: 20,
          },
          [t.breakpoints.down('sm')]: {
            mt: 15,
          },
        })}
      >
        {isMobile ? (
          <MarbleText
            weight="bold"
            size="xl"
            align="left"
            sx={{
              pb: 5,
              pl: 3,
            }}
          >
            How It Works
          </MarbleText>
        ) : (
          <MarbleText
            weight="bold"
            size="h2"
            align="left"
            sx={{
              pb: 5,
            }}
          >
            How It Works
          </MarbleText>
        )}

        <FeatureBox
          header="End to End Tenant Placement"
          descr="Everything from listings to tours to applications. Our placement service finds you tenants in 2.5 weeks on average."
          reverseImgOrder
          moreInfo={{
            text: 'Learn more',
            link: '/self-touring',
          }}
        >
          {isMobile ? (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/1_mobile.webp"
              alt="Leasing CRM View"
            />
          ) : (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/1_mobile.webp"
              alt="Leasing CRM View"
            />
          )}
        </FeatureBox>

        <FeatureBox
          header="24/7 Maintenance Dispatch"
          descr="Never field a maintenance call again. Our team is always on and ready to handle any repair."
          moreInfo={{
            text: 'Learn more',
            link: '/maintenance',
          }}
        >
          {isMobile ? (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/2_mobile.webp"
              alt="Action cards for maintenance workflows"
            />
          ) : (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/2_mobile.webp"
              alt="Action cards for maintenance workflows"
            />
          )}
        </FeatureBox>

        <FeatureBox
          header="Full Digital Management"
          descr="Lose the spreadsheet. Track all rental income and bills through Marble's app."
          reverseImgOrder
        >
          {isMobile ? (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/3_mobile.webp"
              alt="Portfolio list view"
            />
          ) : (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/3_mobile.webp"
              alt="Portfolio list view"
            />
          )}
        </FeatureBox>

        <FeatureBox
          header="Concierge Support Team"
          descr="Great landlords and tenants deserve the best support. Our concierge team is always ready to solve any problems."
        >
          {isMobile ? (
            <Image
              style={{ borderRadius: 5 }}
              width={350}
              height={242}
              src="/img/features/4_mobile.webp"
              alt="Concierge chat example"
            />
          ) : (
            <Image
              style={{ borderRadius: 5 }}
              width={564}
              height={373}
              src="/img/features/4_mobile.webp"
              alt="Concierge chat example"
            />
          )}
        </FeatureBox>
      </Container>
      <Container
        style={{
          backgroundColor: theme.colors.gray[5],
          marginBottom: 100,
          marginTop: 10,
          maxWidth: '100%',
          justifyContent: 'center',
          alignContent: 'center',
          display: 'flex',
        }}
      >
        <Container
          style={{
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 0,
            marginRight: 0,
            color: 'white',
          }}
        >
          <MarbleText
            size="h2"
            weight="med"
            style={{
              marginBottom: 10,
            }}
          >
            Trusted by over 400+ owners, and managing 1000+ units.
          </MarbleText>
          <MarbleText weight="med">
            Here’s what owners and real estate investors who have experienced
            Marble first-hand have to say.
          </MarbleText>
          <Carousel
            indicators
            indicatorContainerProps={{
              style: {
                marginTop: 30,
              },
            }}
            sx={{
              marginTop: 10,
              width: '100%',
            }}
          >
            <CarouselItemInner
              imgSrc="/img/mark-avatar.webp"
              title="SFR | Austin, TX"
              name="Mark"
              body="The Marble platform has made so easy to turn my first home into a rental. After months of searching for traditional management, I opted for Marble. It really feels like someone is totally managing my property, it's incredible!"
            />
            <CarouselItemInner
              imgSrc="/img/erica-avatar.webp"
              title="Duplex | Fresno, CA"
              name="Erica"
              body="Marble gets the job done. I was pleasantly surprised to see how much it get do. It gets all the necessary work done but it's far cheaper than traditional management."
            />
            <CarouselItemInner
              imgSrc="/img/mindy-avatar.webp"
              title="6 SFRs | Atlanta, GA"
              name="Mindy"
              body="Marble is able to answer the call 24/7, so I can sit back and relax. I love that I can be completely hands off, while still keeping an eye on everything in one central place. They are responsive to both the homeowners and the tenants!"
            />
            <CarouselItemInner
              imgSrc="/img/ashly.png"
              title="16 SFRs | SF, CA"
              name="Ashly"
              body="Marble has been fantastic since its inception. They sat down with us and learned about not just our personal but also our professional goals. We strongly suggest our approach to property management. We are confident since they are looking out for our best interests when handling our property."
            />
            <CarouselItemInner
              imgSrc="/img/ivan.webp"
              title="33 Unit Apts | Miami, FL"
              name="Ivan"
              body="I strongly suggest working with Marble to assist you with your renting process! They were helpful, honest, responsive, educated, and a joy to deal with from the start. They actually care about the customer's well-being. We are thankful for having discovered Marble Property Management!"
            />
          </Carousel>
        </Container>
      </Container>

      <Container>
        <Grid
          container
          sx={(t) => ({
            m: 0,
            p: 0,
            mt: 20,
            [t.breakpoints.down('sm')]: {
              flexDirection: 'column',
              mt: 15,
            },
          })}
        >
          <Grid
            item
            sx={(t) => ({
              m: 0,
              p: 0,
              [t.breakpoints.up('sm')]: {
                pr: 15,
                borderRight: `1px solid ${theme.colors.gray[4]}`,
              },
              [t.breakpoints.down('sm')]: {
                pb: 7,
                borderBottom: `1px solid ${theme.colors.gray[4]}`,
              },
            })}
            sm={6}
          >
            <MarbleText size="xl" weight="bold" style={{ margin: 0 }}>
              Need a Rent Estimate?
            </MarbleText>
            <MarbleText size="l" style={{ marginTop: theme.spacing(2) }}>
              We've got you covered. Marble monitors every single rental listing
              site nationwide to get the most up to date and realtime
              information about your rental pricing.
            </MarbleText>
            <Box
              sx={{
                pt: 9,
                // maxWidth: 200,
                // alignSelf: 'center'
              }}
            >
              <MarbleButton
                title="Free Rent Estimate"
                variant="outlined"
                href="/rent-estimate"
                style={{
                  padding: theme.spacing(2),
                  paddingLeft: theme.spacing(4),
                  paddingRight: theme.spacing(4),
                  fontWeight: 600,
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={(t) => ({
              m: 0,
              p: 0,
              [t.breakpoints.up('sm')]: {
                pl: 15,
              },
              [t.breakpoints.down('sm')]: {
                pt: 7,
              },
            })}
            sm={6}
          >
            <MarbleText size="xl" weight="bold" style={{ margin: 0 }}>
              Looking to buy or sell a rental property?
            </MarbleText>
            <MarbleText size="l" style={{ marginTop: theme.spacing(2) }}>
              View the latest off market listings and directly buy/sell with
              other qualified Marble investors
            </MarbleText>
            <Box
              sx={{
                pt: 9,
                // maxWidth: 200,
                // alignSelf: 'center'
              }}
            >
              <MarbleButton
                title="View Direct Offers Catalogue"
                href="/invest"
                style={{
                  padding: theme.spacing(2),
                  paddingLeft: theme.spacing(4),
                  paddingRight: theme.spacing(4),
                  fontWeight: 600,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

interface CarouselItemInnerProps {
  title: string;
  body: string;
  imgSrc: string;
  name: string;
}

function CarouselItemInner(props: CarouselItemInnerProps): JSX.Element {
  return (
    <Container
      sx={{
        backgroundColor: 'white',
        color: 'black',
        padding: 8,
        borderRadius: 1,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.15)',
        mb: 5,
      }}
    >
      <Grid container direction="row" display="flex">
        <Grid item sm={4} xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pb: 2,
              minHeight: 120,
            }}
          >
            <div>
              <Image
                src={props.imgSrc}
                height={60}
                width={60}
                style={{ borderRadius: 30 }}
              />
            </div>
            <Box
              sx={{
                ml: 4,
                pb: 1,
              }}
            >
              <MarbleText weight="bold">{props.name}</MarbleText>
              <MarbleText>{props.title}</MarbleText>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={8} xs={12}>
          <MarbleText>{props.body}</MarbleText>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withMarketingLayout(Home);
